@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";

.x-spreadsheet {
  width: 100% !important;
}

.x-spreadsheet-toolbar {
  width: 100% !important;
}

.x-spreadsheet-sheet {
  width: 100% !important;
}

.x-spreadsheet * {
  scroll-behavior: auto;
}
