.save_btn {
    background-color: #1BC4BF;
    padding :8px 15px;
    color:white;
    text-decoration: none;
    border-radius: 5px;
}
.save_btn:hover {
    color:white;
    text-decoration: none;
}
.cancel_btn {
    background-color: #E2E6EE;
    padding :8px 15px;
    color:#7B828D;
    text-decoration: none;
    border-radius: 5px;
}
.formLabel {
    font-size: 14px;
    font-weight: bold;
}
.cancel_btn:hover {
    color:#7B828D;
    text-decoration: none;
}
.input {
    background-color: #F2F6F9;
    outline:none;
    border:none;
    border-radius: 5px;
}

.profile-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 30px;
    flex-wrap: wrap;
    max-width: 400px;
    row-gap: 10px;
}

.profile-child{
    min-width: 130px;
}


.flex{
    z-index: 100;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -2px;
    column-gap: 10px;
}

.flex label{
    margin-bottom: -1px;
}

.flex input{
    outline: none;
}

.no-border{
    border: none !important;
}


.field{
    font-weight: 600;
}

._main{
    margin-top: -25px;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
    padding: 10px 0;
}

.address{
    display: flex;
    /* flex-wrap: wrap; */
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    row-gap: 5px;
    width: max-content;
}

.address label{
    display: inline;
}

.country{
    width: 50%;
    min-width: 200px;
}

.check_box{
    display: flex;
    align-items: center;
}

.check_box label{
    margin-left: 7px;
}

.time{
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
}

.time_container{
    border-bottom: 1px solid gainsboro;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    max-width: 500px;
}

.disabled{
    pointer-events: none;
    opacity: 0.4;
}

.insurance{
    margin-top: 15px;
}

.membership{
    margin-top: 15px;
}

.insurance input{
    max-width: 150px;
}

.fix{
    margin-bottom: -15px;
    padding-top: 10px;
}

.row_{
    display: flex;
    column-gap: 15px;
    align-items: center;
}

._row{
    padding: 0 17px;
    padding-top: 10px;
    border-top: 1px solid gainsboro;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 7px;
}

._fix{
    max-width: 200px;
}

.pro{
    justify-content: flex-start;
}


.pro .row{
    /* background: red; */
    flex: 50%;
    padding-bottom: 0 !important;
}

.pro input{
    max-width: 200px;
}

.pass{
    padding-top: 25px;
}

.pass_{
    /* border-top: 1px solid gainsboro; */
    padding-top: 10px;
    width: 100%;
}

.pass_ .main{
    max-width: 500px;
}

.pass_ input{
    max-width: 300px;
}

.bank_{
    width: 100%;
    padding: 0 17px;
    padding-top: 15px;
    border-top: 1px solid gainsboro;
}

.bank{
    padding: 5px 17px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    row-gap: 5px;
}

._row_{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0 !important;
}

.dp{
    position: relative;
}

.img-uploader{
    cursor: pointer;
    bottom: 0;
    left: 0;
    position:absolute;
    padding: 5px;
    background-color: black;
    opacity: 0;
    width: fit-content;
    font-weight: bold;
    transition: all ease 0.1s;
}

.dp:hover .img-uploader{
    opacity: 0.5;
}

.img-uploader input{
    display: none;
}

.wrong{
    transition: all ease 0.1s;
    border: 1px solid red !important;
}

.insurance .row{
    width: 50%;
}

.drop{
    min-width: 250px;
}

@media only screen and (max-width: 1260px) {
    .pro .row{
        flex: 100%;
    }
    .insurance ._row{
        row-gap: 20px;
    }
}
@media only screen and (max-width: 432px) {
    .time{
        flex-direction: column;
    }

    .time input{
        max-width: 120px;
    }
    .time_container{
        flex-direction: column;
    }
}
@media only screen and (max-width: 875px) {
    .time input{
        max-width: 120px;
    }
    .time_container{
        flex-direction: column;
    }
  }