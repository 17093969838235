.page-header {
  font-size: 24px;
  margin: 0 0 15px;
  padding: 0;
  border: none;
  line-height: 32px;
  font-weight: 450;
  color: #000;
}

.breadcrumb {
  margin-bottom: 0;
}

.card-body {
  padding: 0 !important;
}

.breadcrumb-item {
  line-height: 32px;
}
.breadcrumb-item a {
  color: #2d353c;
  text-decoration: none !important;
  background-color: transparent;
}

.breadcrumb-item:active {
  color: #6c757d;
}

.btn-white {
  color: #2d353c;
  border-color: #d5dbe0;
  background-color: #ffff;
  font-size: 13px;
  font-weight: 600;
}
.btn-white.active {
  background-color: #d5dbe0 !important;
}
.TicketCardsHo {
  display: flex;
  flex-direction: row;
  position: absolute;
  overflow-x: scroll;
}
.title {
  color: #000;
  margin-bottom: 15px !important;
  font-size: 18px;
  font-weight: 530;
  background: #c7c7c7;
  padding: 10px 7px 7px 7px;
}
.ticketCardWidth {
  width: 25em;
  padding: 10px;
  margin: 0 !important;
}

.card-subtitle {
  margin-left: 7px;
}

.card-footer {
  padding: 2em 5px;
}

.card-link button {
  margin: 0;
  padding: 3px 5px;
  font-size: 10px;
}

.card-link button a,
.card-link button span {
  background-color: #00acac;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.actions {
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 10px;
}
.actions p {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 1em;
}

.min {
  width: 1.7em;
  height: 1.7em;
  margin: 0 0.5em;
  background: orange !important;
  border-radius: 50%;
  border: none;
}

.drpdwn {
  border-radius: 5px;
  line-height: 1;
  background: #f2f3f4;
  color: black;
  height: 2.5em;
}

.dropdown-toggle {
  border-radius: 0;
  line-height: 1;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #f2f3f4;
  border-color: #f2f3f4;
}

.ticketsD.true .ticketCardWidth {
  visibility: visible;
}
.ticketsD.false .ticketCardWidth {
  visibility: hidden;
}

.tickets {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 5em;
}

.ticketsD {
  width: 25em !important;
}

.cards {
  display: flex;
  flex-direction: column;
  height: 60em;
  position: relative;
  overflow-y: auto;
}
.ticketsD.t1 {
  background-color: #efefef;
}
.ticketsD.t1 .actions {
  background-color: #efefef;
}
.ticketsD.t2 {
  background-color: #ff6fff;
}

.ticketsD.t2 .actions {
  background-color: #ff00ff;
}

.ticketsD.t3 {
  background-color: #ffff6f;
}

.ticketsD.t3 .actions {
  background-color: #ff0;
}

.ticketsD.t4 {
  background-color: #c7dff6;
}

.ticketsD.t4 .actions {
  background-color: #9cc6ef;
}

li a {
  font-size: 10px;
}

.btn-green,
.btn-red,
.btn-lime,
.btn-purple,
.btn-orange,
.btn-indigo,
.btn-gray {
  color: #ffff;
}
.btn-green {
  background-color: #32a932;
  border-color: #32a932;
}

.btn-red {
  background-color: #ff5b57;
  border-color: #ff5b57;
}

.btn-lime {
  background-color: #90ca4b;
  border-color: #90ca4b;
}

.btn-purple {
  background-color: #727cb6;
  border-color: #727cb6;
}

.btn-orange {
  background-color: #f59c1a;
  border-color: #f59c1a;
}

.btn-indigo {
  background-color: #8753de;
  border-color: #8753de;
}

.btn-gray {
  background-color: #6c757d;
  border-color: #6c757d;
}

.filters button {
  font-size: 10px;
  font-weight: 600;
  height: 3.5em;
  padding: 1em 2.5em;
  margin: 0 5px;
}

.btn-danger {
  background-color: red !important;
  border-radius: 0;
  border: red;
}
.btn-primary {
  background-color: #49b6d6 !important;
  border-radius: 0;
  border: #49b6d6;
}

.filters {
  display: flex;
  justify-content: space-between;
}

.search {
  flex-direction: row;
}

.dropdown-menu .divider {
  border-color: #e5e5e5;
}
.dropdown-divider,
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu,
ul#priority-ul {
  min-width: 85px !important;
}

.btn-danger.active,
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger:focus,
.btn-danger:hover,
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.open > .dropdown-toggle.btn-danger,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover,
.show > .btn-danger.dropdown-toggle {
  background: #cc4946 !important;
  border-color: #cc4946 !important;
}

.btn.btn-danger,
.btn.btn-danger.disabled,
.btn.btn-danger.disabled:focus,
.btn.btn-danger.disabled:hover,
.btn.btn-danger[disabled],
.btn.btn-danger[disabled]:focus,
.btn.btn-danger[disabled]:hover {
  color: #fff;
  background: #ff5b57 !important;
  border-color: #ff5b57 !important;
}

h4.title.m-t-0.card-title {
  border-radius: 5px 5px 0px 0px;
}
.card-footer:last-child {
  border-radius: 0 0 5px 5px;
}
.ticketCardWidth {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.filters button {
  font-size: 11px;
  font-weight: 600;
}

.daterange {
  position: absolute;
  z-index: 5;
  right: 50px;
}

select.filterbtn {
  /* flex-wrap: wrap; */
  margin: 0 5px;
  width: 100px;
  background: #2b9fc1;
  color: white;
  height: 39px;
}

.fitersearch {
  height: 39px !important;
  display: block;
  width: 100%;
  padding: 0.4375rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2d353c;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d5dbe0;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rdrDays {
  width: 100% !important;
}
/* .rdrMonth{
  width: 30vw !important;
  max-width: 760px !important;
} */
@media screen and (max-width: 567px) {
  .search {
    flex-wrap: wrap;
  }

  .daterange {
    display: flex;
    flex-direction: column-reverse;
  }
  .rdrStaticRanges {
    display: flex;
    flex-direction: row;
  }
  .rdrDefinedRangesWrapper {
    display: flex;
  }
  .rdrInputRanges {
    padding: 5px 0;
    display: flex;
  }
  .daterange {
    right: 0px;
  }
  .rdrDefinedRangesWrapper {
    display: flex;
    flex-wrap: wrap;
  }
  .rdrStaticRangeLabel {
    display: block;
    outline: 0;
    line-height: 18px;
    padding: 6px 8px;
    text-align: left;
  }
  .rdrMonths {
    display: flex;
    flex-direction: column;
  }
  .searchbtn {
    display: none !important;
  }
  /* .rdrDayNumber{
    padding: 2vw ;
  } */
  .rdrDays {
    width: 100% !important;
  }
  .rdrMonth {
    width: 100vw;
  }
  .rdrInputRange {
    width: 50vw;
  }
  .rdrStaticRangeLabel {
    width: 7em;
  }
  .rdrStaticRanges {
    flex-flow: row wrap;
  }
  .rdrDateRangePickerWrapper {
    background: white;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
    justify-content: space-between !important;
  }
  .rdrStaticRanges {
    justify-content: space-evenly !important;
  }
}
