.wrapper{
    top: 0 !important;
    margin-bottom: -50px !important;
    position: relative !important;
    width: 210px !important;
}

/* .wrapper:focus{
    box-shadow: rgb(63, 186, 202) !important;
}

.wrapper:hover{
    box-shadow: none !important;
} */