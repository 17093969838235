@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Manrope:wght@300&family=Montserrat:wght@300;400&family=Nunito+Sans&display=swap");
* {
  font-family: "Inter", sans-serif;
  font-family: "Manrope", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Nunito Sans", sans-serif;
}

.legend-text {
  color: #707478;
  border-bottom: 1px solid #e2e7eb;
  letter-spacing: 1.3px;
}

.res-textarea {
  width: 75% !important;
}

.select-width {
  width: 300px !important;
}

.unit {
  width: 10% !important;
  max-width: 10% !important;
}

@media only screen and (max-width: 600px) {
  .res-textarea {
    width: 100% !important;
  }
  .select-width {
    width: 100%;
  }
}
Select {
  opacity: 1;
}
.selectPatient {
  width: 500px;
  padding: 7px 10px;
  border-radius: 3px;
  border-color: #a4abb3;
}

/* medical files update */

.inputBox {
  margin-bottom: 15px;
}

.tab-container {
  width: 100%;
  justify-content: center;
  display: flex;
  margin: 15px 0;

  align-items: center;
}

table {
  width: 100%;
}
.familyHistoryTable {
  width: 100%;
  height: 200px;
  max-height: 300px;
}

.tabs {
  font-size: 12px;
  letter-spacing: 1.2px;
  font-family: "Manrope", sans-serif;
  word-wrap: break-word;
  color: white;
  margin: 0 5px;
  width: 120px;
  font-weight: 500;
  padding: 8px;
  height: 55px;
  border-radius: 3px;
  text-align: center;
  margin: 4px;
}
